import React, { useState, useMemo, useEffect } from "react"
import {
  Map,
  Placemark,
  ZoomControl,
  Clusterer,
  FullscreenControl,
} from "react-yandex-maps"
import Pin from "../../assets/pin.svg"
import useObjects from "../../hooks/use-objects"
import { navigate } from "gatsby"

const ProjectMap = () => {
  const objectsData = useObjects()
  const [zoom] = useState(10)
  const [template, setTemplate] = useState({ icon: null })
  const [center] = useState([58.015857, 56.241121])
  const mapState = useMemo(() => ({ center, zoom }), [center, zoom])
  const [buildings, setCoordinates] = useState([])
  const [isMounted, setMount] = useState()

  useEffect(() => {
    setMount(true) // note this flag denote mount status
    window.navigate = adress => {
      navigate(adress)
    }
    return () => {
      setMount(false)
      window.navigate = {}
    } // use effect cleanup to set flag false, if unmounted
  }, [])

  const getGeoLocation = (ymaps, objectsData) => {
    const asyncRes = Promise.all(
      objectsData.map(async objectBuilding => {
        const objectWithCoordinates = { ...objectBuilding }
        const geoObject = await ymaps.geocode(
          objectWithCoordinates.objects.adress
        )
        let coordinates = geoObject.geoObjects.get(0).geometry.getCoordinates()
        if (!objectWithCoordinates.objects.coordinates.latitude) {
          objectWithCoordinates.objects.coordinates.latitude = coordinates[0]
          objectWithCoordinates.objects.coordinates.longitude = coordinates[1]
        }
        return objectWithCoordinates
      })
    )

    asyncRes
      .then(result => {
        if (isMounted) setCoordinates(result)
      })
      .catch(error => {
        console.error(error.message)
      })
  }

  const handleApiAvaliable = ymaps => {
    const myIconLayout = ymaps.templateLayoutFactory.createClass(
      [
        '<svg class="icon-3e31be50" width="60" height="60" style="position: absolute; top: -60px; left: -20px;">',
        '<use xlink:href="#icon-3e31be50" xmlns:xlink="http://www.w3.org/1999/xlink"></use>',
        "</svg>",
      ].join("")
    )

    if (isMounted) setTemplate({ icon: myIconLayout })

    getGeoLocation(ymaps, objectsData)
  }

  const listItems = buildings.map(building => (
    <Placemark
      key={building.id}
      geometry={[
        building.objects.coordinates.latitude,
        building.objects.coordinates.longitude,
      ]}
      properties={{
        balloonContentHeader: `${building.objects.owner}`,
        balloonContentBody: `${building.objects.adress}`,
        balloonContentFooter: `<input type="button" onclick="window.navigate('/objects/${building.slug}/');" value="Подробнее.."/>`,
        hintContent: `${building.objects.adress}`,
      }}
      options={{
        iconLayout: template.icon,
        iconShape: {
          type: "Circle",
          coordinates: [0, 0],
          radius: 40,
        },
      }}
    />
  ))

  return (
    <div
      style={{
        minHeight: "50vh",
        margin: "2rem 0",
        width: "100%",
        backgroundColor: "lightgrey",
      }}
    >
      <Pin />
      <Map
        modules={[
          "geolocation",
          "geocode",
          "templateLayoutFactory",
          "layout.ImageWithContent",
          "geoObject.addon.balloon",
          "geoObject.addon.hint",
        ]}
        onLoad={ymaps => handleApiAvaliable(ymaps)}
        state={mapState}
        style={{ height: "50vh", margin: "2rem 0", width: "100%" }}
        defaultState={{ center: [55.75, 37.57], zoom: 12 }}
      >
        <Clusterer
          options={{
            preset: "islands#nightClusterIcons",
            groupByCoordinates: false,
          }}
        >
          {listItems}
        </Clusterer>
        <ZoomControl />
        <FullscreenControl />
      </Map>
    </div>
  )
}

export default ProjectMap
