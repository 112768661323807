import { graphql, useStaticQuery } from "gatsby"

const useObjects = () => {
  const data = useStaticQuery(graphql`
    {
      wordPress {
        objectBuildings(first: 100) {
          nodes {
            title
            id
            slug
            objects {
              owner
              adress
              coordinates {
                latitude
                longitude
              }
            }
          }
        }
      }
    }
  `)

  return data.wordPress.objectBuildings.nodes
}

export default useObjects
