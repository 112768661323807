import React, { Component } from "react"
import { graphql, navigate, Link } from "gatsby"
import styled from "@emotion/styled"
import Map from "../components/map/index"
import { Helmet } from "react-helmet"
import HomepageLayout from "../components/layout/index.js"
import Row from "../components/layout/row.js"
import Paginate from "../components/common/paginate"

const ActiveLink = props => <Link {...props} activeClassName="current-page" />

const NavLink = styled(ActiveLink)`
  display: block;
  color: ${props => props.theme.colors.navLink};
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1;
  margin: 0px 10px 13px 0;
  padding: 10px 14px;
  text-decoration: none;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  border-radius: 10px;

  ${props => props.theme.tablet} {
    margin: 0 0 10px 0;
  }

  &.current-page {
    color: #123963;
    padding: 9px 13px;
    border: 2px solid #123963;
  }

  &:hover {
    color: ${props => props.theme.colors.hoverLink};
  }

  &:last-of-type {
    margin-right: 0px;
  }
`

const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -2rem;
  ${props => props.theme.tablet} {
    margin: 0;
  }
`
const ObjectWrapper = styled.div`
  width: 33.3%;
  margin-bottom: 4rem;
  ${props => props.theme.tablet} {
    width: 100%;
  }
`

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.5rem 0rem 1rem;
`

const Adress = styled.span`
  font-size: 0.8rem;
  color: ${props => props.theme.colors.secondary};
`

const StyledLink = styled(Link)`
  border-bottom: 1px solid ${props => props.theme.colors.line};
  display: block;
  margin: 0 2rem;
  min-height: 200px;
  word-wrap: break-word;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  &:hover {
    color: ${props => props.theme.colors.activeLink};
  }
  img {
    width: 100%;
    min-height: 250px;
    background-color: rgba(0, 0, 0, 0.05);
  }

  ${props => props.theme.tablet} {
    margin: 0;
  }
`
const Owner = styled.span`
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: black;
  text-transform: uppercase;
  ${StyledLink}:hover & {
    color: ${props => props.theme.colors.activeLink};
  }
`

const MapHeader = styled.span`
  font-size: 2rem;
  font-weight: 500;
  width: auto;
  z-index: 3;
  padding: 0 2rem;
  background-color: #f3f3f3;
  position: relative;
  margin: 0;
  z-index: 3;
  display: block;
  margin: 0 auto;
  text-align: center;
  ${props => props.theme.tablet} {
    font-size: 1.3rem;
  }
`

const Navigation = styled.div`
  display: flex;
  margin-bottom: 20px;
  ${props => props.theme.tablet} {
    flex-direction: column;
    flex: 1 1 auto;
  }
`

class ObjectsPage extends Component {
  handlePageClick = (data, uri) => {
    if (data.selected + 1 === 1) {
      navigate(`/objects/${uri}/`)
      return
    }
    navigate(`/objects/${uri}/page/${data.selected + 1}`)
  }

  render() {
    const {
      data,
      pageContext: { pageNumber, totalPages, header, uri },
    } = this.props

    return (
      <HomepageLayout>
        <Helmet>
          <title>{`Объекты в работе Фонда, страница ${
            parseInt(pageNumber) + 1
          }`}</title>
          <meta
            name="description"
            content="Объекты в работе Фонда Защиты Прав Граждан участников строительства в ПК"
          />
        </Helmet>
        <Row margin="2rem auto">
          <Navigation>
            <NavLink partiallyActive={true} to="/objects/in-work/">
              В работе фонда
            </NavLink>
            <NavLink partiallyActive={true} to="/objects/payout/">
              Инициирован пересмотр на выплаты
            </NavLink>
            <NavLink partiallyActive={true} to="/objects/withdrawal/">
              Инициирован отзыв решения
            </NavLink>
            <NavLink partiallyActive={true} to="/objects/done/">
              Введены в эксплуатацию
            </NavLink>
          </Navigation>

          <h1>{header}</h1>
          <LinksWrapper>
            {data?.wordPress?.objectBuildings?.nodes.map(object => (
              <ObjectWrapper key={object.id}>
                <StyledLink to={`/objects/${object.slug}`} key={object.id}>
                  {object.objects.cover && (
                    <img
                      src={object.objects.cover.sourceUrl}
                      alt={object.objects.adress}
                    />
                  )}
                  <InfoWrapper>
                    <Owner>{object.title}</Owner>
                    <Adress>{object.objects.owner}</Adress>
                  </InfoWrapper>
                </StyledLink>
              </ObjectWrapper>
            ))}
          </LinksWrapper>
          <Paginate
            totalPages={totalPages}
            handlePageClick={data => this.handlePageClick(data, uri)}
            pageNumber={parseInt(pageNumber)}
          />
          <MapHeader>Карта объектов в работе фонда</MapHeader>
          <Map />
        </Row>
      </HomepageLayout>
    )
  }
}

export default ObjectsPage

export const query = graphql`
  query GET_OBJECTS($ids: [ID]) {
    wordPress {
      objectBuildings(where: { in: $ids }) {
        nodes {
          id
          title
          slug
          objects {
            owner
            adress
            cover {
              sourceUrl(size: MEDIUM_LARGE)
            }
          }
        }
      }
    }
  }
`
